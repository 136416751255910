import { 
  auth,
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut as firebaseSignOut,
  updateProfile
} from '../firebase';
import { User, CreateUserDTO, UserCredentials } from '../types';
import { setUser, clearUser } from '../store/slices/userSlice';
import { setSelectedOrganization, addOrganization } from '../store/slices/organizationSlice'; // Import actions for organization handling
import { createOrganization } from './organization.service'; // Import organization creation logic
import { AppDispatch } from '../store';
import { db } from '../firebase';
import { setDoc, getDoc, doc, serverTimestamp } from 'firebase/firestore';
import { fetchUserFromFirestore } from './user.service';  // Correct import

export const signUp = async (userData: CreateUserDTO, dispatch: AppDispatch): Promise<User> => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, userData.email, userData.password);
    const user = userCredential.user;
    
    await updateProfile(user, { displayName: userData.username });

    // Create the User Object
    const newUser: User = {
      id: user.uid,
      username: userData.username,
      email: user.email!,
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    // Save user profile in Firestore
    const userDoc = doc(db, 'users', user.uid);
    await setDoc(userDoc, {
      ...newUser,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    // Dispatch the user to the store
    dispatch(setUser(newUser));

    // Automatically create an organization for the new user
    const orgName = `${userData.username}'s Organization`;
    const newOrganization = await createOrganization({
      name: orgName,
      ownerId: user.uid,
      ownerUsername: userData.username // Add ownerUsername property
    });

    // Dispatch the new organization to the store
    dispatch(addOrganization(newOrganization));
    dispatch(setSelectedOrganization(newOrganization));

    return newUser;
  } catch (error) {
    console.error('Sign up error:', error);
    throw error;
  }
};

// Sign in a user and fetch their Firestore profile
export const signIn = async (credentials: UserCredentials, dispatch: AppDispatch): Promise<User> => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, credentials.email, credentials.password);
    const user = userCredential.user;

    // Fetch user profile from Firestore
    const fetchedUser = await fetchUserFromFirestore(user.uid);

    // Dispatch to Redux
    dispatch(setUser(fetchedUser));
    return fetchedUser;
  } catch (error) {
    console.error('Sign in error:', error);
    throw error;
  }
};

// Sign out the current user and clear their state
export const signOut = async (dispatch: AppDispatch): Promise<void> => {
  try {
    await firebaseSignOut(auth);
    dispatch(clearUser());
  } catch (error) {
    console.error('Sign out error:', error);
    throw error;
  }
};

