import { Document } from '../types/document';
import { serverTimestamp, collection, query, where, getDocs, QueryDocumentSnapshot, addDoc, Timestamp, DocumentData } from 'firebase/firestore';
import { db } from '../firebase';

export const getDocuments = async (organizationId: string, projectId?: string): Promise<Document[]> => {
    try {
      let q = query(collection(db, 'documents'), where('organizationId', '==', organizationId));
      if (projectId) {
        q = query(q, where('projectId', '==', projectId));
      }
      const snapshot = await getDocs(q);
  
      return snapshot.docs.map((doc: QueryDocumentSnapshot<DocumentData>) => {
        const data = doc.data() as Document;
        const { id, ...restData } = data; // Destructure to exclude id

        return {
          id: doc.id,
          ...restData,
          createdAt: data.createdAt instanceof Timestamp ? data.createdAt.toDate() : null,
          updatedAt: data.updatedAt instanceof Timestamp ? data.updatedAt.toDate() : null,
        };
      });
    } catch (error) {
      console.error('Error fetching documents:', error);
      throw error;
    }
  };

  export const createDocument = async (
    data: Omit<Document, 'id' | 'createdAt' | 'updatedAt'>,
    userId: string
  ): Promise<Document> => {
    try {
      // Create a new object with only defined properties
      const documentData = Object.entries(data).reduce((acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = value;
        }
        return acc;
      }, {} as Record<string, any>);
  
      // Add required fields
      documentData.createdAt = serverTimestamp();
      documentData.updatedAt = serverTimestamp();
      documentData.createdBy = userId;
  
      const docRef = await addDoc(collection(db, 'documents'), documentData);
      
      // Convert Timestamp to Date for the returned object
      const createdAt = documentData.createdAt instanceof Timestamp 
        ? documentData.createdAt.toDate() 
        : new Date();
      const updatedAt = documentData.updatedAt instanceof Timestamp 
        ? documentData.updatedAt.toDate() 
        : new Date();
  
      return { 
        id: docRef.id, 
        ...data, 
        createdAt, 
        updatedAt 
      } as Document;
    } catch (error) {
      console.error('Error creating document:', error);
      throw error;
    }
  };
