import React from 'react';
import Layout from '../components/Layout';
import InvitationsList from '../components/InvitationsList';

const Invitations: React.FC = () => {
  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-6">Your Invitations</h1>
      <InvitationsList />
    </Layout>
  );
};

export default Invitations;