import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { setSelectedDocument } from '../store/slices/documentSlice';
import DocumentViewer from './DocumentViewer';
import { Document } from '../types/document';
import { FileText } from 'lucide-react';

interface DocumentListProps {
  documents: Document[];
  onViewDocument?: (documentId: string, documentName: string) => void;
}

const DocumentList: React.FC<DocumentListProps> = ({ documents, onViewDocument }) => {
  const dispatch = useDispatch();
  const [showViewer, setShowViewer] = useState(false);
  const selectedDocument = useSelector((state: RootState) => state.document.selectedDocument);

  const handleDocumentClick = (doc: Document) => {
    dispatch(setSelectedDocument(doc));
    if (onViewDocument) {
      onViewDocument(doc.id, doc.name);
    } else {
      setShowViewer(true);
    }
  };

  if (documents.length === 0) {
    return <div className="text-gray-500 italic">No documents available.</div>;
  }

  return (
    <div>
      <ul className="space-y-2">
        {documents.map((doc: Document) => (
          <li key={doc.id} className="bg-white p-4 rounded-lg shadow hover:shadow-md transition-shadow duration-200">
            <button 
              onClick={() => handleDocumentClick(doc)}
              className="flex items-center w-full text-left focus:outline-none"
            >
              <FileText className="h-5 w-5 text-blue-500 mr-3" />
              <div className="flex-grow">
                <span className="font-medium text-gray-900">{doc.name}</span>
                <span className="text-sm text-gray-500 ml-2 block">
                  {doc.createdAt instanceof Date 
                    ? doc.createdAt.toLocaleDateString() 
                    : doc.createdAt 
                      ? new Date(doc.createdAt).toLocaleDateString() 
                      : 'Invalid Date'}
                </span>
              </div>
              <span className="px-3 py-1 bg-blue-100 text-blue-700 rounded-md hover:bg-blue-200 transition-colors duration-200">
                View
              </span>
            </button>
          </li>
        ))}
      </ul>
      {!onViewDocument && showViewer && selectedDocument && (
        <DocumentViewer document={selectedDocument} onClose={() => setShowViewer(false)} />
      )}
    </div>
  );
};

export default DocumentList;