import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { getTask, updateTask } from '../services/task.service';
import { setCurrentTask } from '../store/slices/taskSlice';
import { createChatMessage, getChatMessages } from '../services/chat.service';
import Layout from '../components/Layout';
import { Task } from '../types';
import { Edit2, Send } from 'lucide-react';
import TaskEditForm from '../components/TaskEditForm';
import TaskInfo from '../components/TaskInfo';
import ChatMessage from '../components/ChatMessage';
import { ChatMessage as ChatMessageType } from '../types/chat';

const TaskDetails: React.FC = () => {
  const { taskId } = useParams<{ taskId: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentTask = useSelector((state: RootState) => state.task.currentTask);
  const currentUser = useSelector((state: RootState) => state.user.currentUser);

  const [messages, setMessages] = useState<ChatMessageType[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchTaskAndMessages = async () => {
      if (taskId) {
        try {
          const task = await getTask(taskId);
          if (task) {
            dispatch(setCurrentTask(task));
            const fetchedMessages = await getChatMessages(taskId);
            setMessages(fetchedMessages);
          } else {
            console.error('Task not found');
            navigate('/dashboard');
          }
        } catch (error) {
          console.error('Error fetching task details:', error);
          navigate('/dashboard');
        }
      }
    };

    fetchTaskAndMessages();
  }, [taskId, dispatch, navigate]);

  const handleSendMessage = async () => {
    if (newMessage.trim() && currentUser && currentTask) {
      const messageData = {
        taskId: currentTask.id,
        userId: currentUser.id,
        username: currentUser.username,
        content: newMessage.trim(),
      };
      const createdMessage = await createChatMessage(messageData);
      setMessages([...messages, createdMessage]);
      setNewMessage('');
    }
  };

  const handleUpdateTask = async (updatedFields: Partial<Task>) => {
    if (currentTask) {
      try {
        const updatedTask = await updateTask(currentTask.id, updatedFields);
        dispatch(setCurrentTask(updatedTask));
        setIsEditing(false);
      } catch (error) {
        console.error('Error updating task:', error);
      }
    }
  };

  const isValidDate = (date: any) => {
    return date instanceof Date && !isNaN(date.getTime());
  };

  if (!currentTask) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="bg-indigo-600 px-6 py-4">
            <div className="flex justify-between items-center">
              <h1 className="text-2xl font-bold text-white">{currentTask.title}</h1>
              <button
                onClick={() => setIsEditing(!isEditing)}
                className="text-white hover:text-indigo-200"
              >
                <Edit2 className="h-5 w-5" />
              </button>
            </div>
          </div>
          <div className="px-6 py-4">
            {isEditing ? (
              <TaskEditForm
                task={currentTask}
                onSubmit={handleUpdateTask}
                onCancel={() => setIsEditing(false)}
                isValidDate={isValidDate}
              />
            ) : (
              <TaskInfo task={currentTask} />
            )}
          </div>
        </div>

        <div className="mt-8 bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="px-6 py-4">
            <h2 className="text-xl font-semibold mb-4">Discussion</h2>
            <div className="space-y-4 mb-4 max-h-96 overflow-y-auto">
              {messages.map((message, index) => (
                <ChatMessage key={index} message={message} />
              ))}
            </div>
            <div className="flex">
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type your message..."
                className="flex-grow px-4 py-2 border rounded-l-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
              <button
                onClick={handleSendMessage}
                className="bg-indigo-600 text-white px-4 py-2 rounded-r-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                <Send className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TaskDetails;