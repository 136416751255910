import { getFirestore, collection, addDoc, getDocs, query, where, orderBy, limit, doc, deleteDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { ChatMessage, CreateChatMessageDTO } from '../types/chat';

const db = getFirestore(); // Initialize Firestore instance


export const createChatMessage = async (messageData: CreateChatMessageDTO): Promise<ChatMessage> => {
    try {
      const timestamp = new Date();
      const docRef = await addDoc(collection(db, 'chatMessages'), {
        ...messageData,
        timestamp,
      });
      return { 
        id: docRef.id, 
        ...messageData, 
        timestamp,
      };
    } catch (error) {
      console.error('Error creating chat message:', error);
      throw error;
    }
  };
export const getChatMessages = async (taskId: string, messageLimit: number = 50): Promise<ChatMessage[]> => {
    try {
      const q = query(
        collection(db, 'chatMessages'), 
        where('taskId', '==', taskId),
        orderBy('timestamp', 'desc'),
        limit(messageLimit) // Use messageLimit instead of limit
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => {
        const data = doc.data();
        return { 
          id: doc.id, 
          ...data, 
          timestamp: data.timestamp.toDate(),
        } as ChatMessage;
      }).reverse();
    } catch (error) {
      console.error('Error getting chat messages:', error);
      throw error;
    }
  };
  

export const deleteChatMessage = async (messageId: string): Promise<void> => {
  try {
    await deleteDoc(doc(db, 'chatMessages', messageId));
  } catch (error) {
    console.error('Error deleting chat message:', error);
    throw error;
  }
};

export const updateChatMessage = async (messageId: string, content: string): Promise<void> => {
  try {
    const messageRef = doc(db, 'chatMessages', messageId);
    await updateDoc(messageRef, { 
      content, 
      updatedAt: new Date(),
    });
  } catch (error) {
    console.error('Error updating chat message:', error);
    throw error;
  }
};

export const getLatestChatMessage = async (taskId: string): Promise<ChatMessage | null> => {
  try {
    const q = query(
      collection(db, 'chatMessages'), 
      where('taskId', '==', taskId),
      orderBy('timestamp', 'desc'),
      limit(1)
    );
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      const data = doc.data();
      return { 
        id: doc.id, 
        ...data, 
        timestamp: (data.timestamp instanceof Timestamp) ? data.timestamp.toDate() : data.timestamp,
      } as ChatMessage;
    }
    return null;
  } catch (error) {
    console.error('Error getting latest chat message:', error);
    throw error;
  }
};