import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Document, DocumentsState } from '../../types/document';

const initialState: DocumentsState = {
  selectedDocument: null,
  documents: [],
};

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    setDocuments(state, action: PayloadAction<Document[]>) {
      state.documents = action.payload;
    },
    addDocument(state, action: PayloadAction<Document>) {
      state.documents.push(action.payload);
    },
    setSelectedDocument(state, action: PayloadAction<Document | null>) {
      state.selectedDocument = action.payload;
    },
  },
});

export const { setDocuments, addDocument, setSelectedDocument } = documentSlice.actions;
export default documentSlice.reducer;