import React, { useState, useEffect } from 'react';
import { Project, CreateProjectDTO, UpdateProjectDTO } from '../types/project';
import { PlusIcon, XIcon } from 'lucide-react';

interface ProjectFormProps {
  project?: Project;
  onSubmit: (projectData: CreateProjectDTO | UpdateProjectDTO) => void;
  onCancel?: () => void;
  organizationId: string; // Add organizationId to the props
}

const ProjectForm: React.FC<ProjectFormProps> = ({ project, onSubmit, onCancel, organizationId }) => {
  const [name, setName] = useState(project?.name || '');
  const [description, setDescription] = useState(project?.description || '');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (project) {
      setName(project.name);
      setDescription(project.description);
    }
  }, [project]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (!name.trim()) {
      setError('Project name is required');
      return;
    }

    const projectData: CreateProjectDTO = {
      name: name.trim(),
      description: description.trim(),
      ownerId: project?.ownerId || '', // Use the existing ownerId or an empty string
      organizationId, // Include the organizationId
    };

    onSubmit(projectData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6 bg-white shadow-sm rounded-lg p-6">
      <div>
        <label htmlFor="project-name" className="block text-sm font-medium text-gray-700">
          Project Name
        </label>
        <input
          type="text"
          id="project-name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          placeholder="Enter project name"
          />
          </div>
          <div>
          <label htmlFor="project-description" className="block text-sm font-medium text-gray-700">
          Description
          </label>
          <textarea
          id="project-description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          rows={3}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          placeholder="Enter project description"
          />
          </div>
          {error && (
          <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
          <div className="flex-shrink-0">
          <XIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{error}</h3>
          </div>
          </div>
          </div>
          )}
          <div className="flex justify-end space-x-3">
          {onCancel && (
          <button
          type="button"
          onClick={onCancel}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
          Cancel
          </button>
          )}
          <button
          type="submit"
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
          <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          {project ? 'Update Project' : 'Create Project'}
          </button>
          </div>
          </form>
          );
          };
          export default ProjectForm;