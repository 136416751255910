import React from 'react';
import { XIcon, DownloadIcon, CalendarIcon, UserIcon } from 'lucide-react';
import { Document } from '../types/document';

interface DocumentViewerProps {
  document: Document;
  onClose: () => void;
}

const DocumentViewer: React.FC<DocumentViewerProps> = ({ document, onClose }) => {
  const renderContent = () => {
    const fileExtension = document.name.split('.').pop()?.toLowerCase();

    switch (fileExtension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return <img src={document.url} alt={document.name} className="max-w-full h-auto" />;
      case 'pdf':
        return <iframe src={document.url} className="w-full h-[60vh]" title={document.name} />;
      default:
        return <pre className="whitespace-pre-wrap overflow-auto max-h-[60vh]">{document.content}</pre>;
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-5 border w-11/12 md:w-3/4 lg:w-2/3 shadow-lg rounded-md bg-white">
        <div className="flex justify-between items-center pb-3 border-b">
          <h3 className="text-xl font-semibold text-gray-900">{document.name}</h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500 transition duration-150 ease-in-out"
          >
            <XIcon className="h-6 w-6" />
          </button>
        </div>
        <div className="mt-4 flex items-center text-sm text-gray-500 space-x-4">
          <span className="flex items-center">
            <CalendarIcon className="h-4 w-4 mr-1" />
            {new Date(document.createdAt ?? '').toLocaleDateString()}
          </span>
          <span className="flex items-center">
            <UserIcon className="h-4 w-4 mr-1" />
            {document.createdBy}
          </span>
        </div>
        <div className="mt-4 bg-gray-50 rounded-md p-4">
          {renderContent()}
        </div>
        <div className="mt-6 flex justify-between items-center">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200 transition duration-150 ease-in-out"
          >
            Close
          </button>
          {document.url && (
            <a
              href={document.url}
              download={document.name}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-150 ease-in-out flex items-center"
            >
              <DownloadIcon className="h-4 w-4 mr-2" />
              Download
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentViewer;