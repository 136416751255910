import React, { useState } from 'react';
import { Task, TaskStatus } from '../types';
import { MoreVertical, Edit2, Trash2, FileText } from 'lucide-react';

interface TaskBoardProps {
  tasks: Task[];
  onEditTask: (task: Task) => void;
  onDeleteTask: (taskId: string) => void;
  onViewDocument?: (documentId: string, documentName: string) => void;
  onSelectTask: (taskId: string) => void; // Add this line

}

const TaskBoard: React.FC<TaskBoardProps> = ({ tasks, onEditTask, onDeleteTask, onViewDocument, onSelectTask }) => {
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);

  const columns: { [key in TaskStatus]: string } = {
    todo: 'To Do',
    inProgress: 'In Progress',
    done: 'Done',
  };

  const toggleDropdown = (taskId: string) => {
    setOpenDropdownId(openDropdownId === taskId ? null : taskId);
  };

  return (
    <div className="flex space-x-4 overflow-x-auto pb-4">
      {Object.entries(columns).map(([status, title]) => {
        const tasksInColumn = tasks.filter((task) => task.status === status);
        
        return (
          <div key={status} className="flex-1 min-w-[300px] bg-gray-100 p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-4">{title}</h3>
            <div className="space-y-4">
              {tasksInColumn.map((task) => (
  <div 
  key={task.id} 
  className="bg-white p-4 rounded-lg shadow hover:shadow-md transition-shadow duration-300 cursor-pointer"
  onClick={() => onSelectTask(task.id)} // Add this line
>                  <div className="flex justify-between items-start">
                    <h4 className="font-semibold mb-2">{task.title}</h4>
                    <div className="relative">
                      <button 
                        onClick={() => toggleDropdown(task.id)}
                        className="text-gray-500 hover:text-gray-700"
                        
                      >
                        <MoreVertical size={16} />
                      </button>
                      {openDropdownId === task.id && (
                        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                          <div className="py-1">
                            <button
                              onClick={() => {
                                onEditTask(task);
                                setOpenDropdownId(null);
                              }}
                              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              <Edit2 size={14} className="inline mr-2" />
                              Edit
                            </button>
                            <button
                              onClick={() => {
                                onDeleteTask(task.id);
                                setOpenDropdownId(null);
                              }}
                              className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                            >
                              <Trash2 size={14} className="inline mr-2" />
                              Delete
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <p className="text-sm text-gray-600">{task.description}</p>
                  {task.dueDate && (
                    <p className="text-xs text-gray-500 mt-2">
                      Due: {new Date(task.dueDate).toLocaleDateString()}
                    </p>
                  )}
                  {task.documentName && (
                    <div className="flex items-center mt-2">
                      <FileText className="h-4 w-4 mr-1 text-blue-500" />
                      <span 
                        className="text-sm text-blue-500 cursor-pointer hover:underline"
                        onClick={() => {
                          if (onViewDocument && task.documentId && task.documentName) {
                            onViewDocument(task.documentId, task.documentName);
                          }
                        }}
                      >
                        {task.documentName}
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TaskBoard;