import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Organization, Invitation } from '../../types/organization';

interface OrganizationState {
  organizations: Organization[];
  invitations: Invitation[];
  selectedOrganization: Organization | null;
  error: string | null;
}

const initialState: OrganizationState = {
  organizations: [],
  invitations: [],
  selectedOrganization: null,
  error: null,
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganizations(state, action: PayloadAction<Organization[]>) {
      state.organizations = action.payload;
    },
    setInvitations(state, action: PayloadAction<Invitation[]>) {
      state.invitations = action.payload;
    },
    setSelectedOrganization(state, action: PayloadAction<Organization | null>) {
      state.selectedOrganization = action.payload;
    },
    addOrganization(state, action: PayloadAction<Organization>) {
      state.organizations.push(action.payload);
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    updateInvitationStatus(state, action: PayloadAction<{ id: string; status: 'accepted' | 'rejected' }>) { // Add this action
      const invitationIndex = state.invitations.findIndex(inv => inv.id === action.payload.id);
      if (invitationIndex !== -1) {
        state.invitations[invitationIndex].status = action.payload.status;
      }
    },
  },
});

export const { setOrganizations, setInvitations, setSelectedOrganization, addOrganization, setError, updateInvitationStatus } = organizationSlice.actions;
export default organizationSlice.reducer;
