import React from 'react';
import { ChatMessage as ChatMessageType } from '../types/chat';

interface ChatMessageProps {
  message: ChatMessageType;
}

const ChatMessage: React.FC<ChatMessageProps> = ({ message }) => {
  return (
    <div className="bg-gray-100 rounded-lg p-3">
      <div className="flex justify-between items-baseline mb-1">
        <span className="font-semibold text-sm">{message.username}</span>
        <span className="text-xs text-gray-500">{new Date(message.timestamp).toLocaleString()}</span>
      </div>
      <p className="text-sm">{message.content}</p>
    </div>
  );
};

export default ChatMessage;