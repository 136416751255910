import React from 'react';
import { Task } from '../types';
import { Clock, CheckSquare } from 'lucide-react';

interface TaskInfoProps {
  task: Task;
}

const TaskInfo: React.FC<TaskInfoProps> = ({ task }) => {
  const formatDate = (date: Date | undefined) => {
    return date ? new Date(date).toLocaleDateString() : 'No due date';
  };

  return (
    <div>
      <p className="text-gray-600 mb-4">{task.description}</p>
      <div className="flex items-center text-sm text-gray-500 mb-2">
        <Clock className="h-4 w-4 mr-2" />
        <p>Due Date: {formatDate(task.dueDate)}</p>
      </div>
      <div className="flex items-center text-sm text-gray-500">
        <CheckSquare className="h-4 w-4 mr-2" />
        <span>Status: {task.status}</span>
      </div>
    </div>
  );
};

export default TaskInfo;