import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../store';
import { setProjects } from '../store/slices/projectSlice';
import { setDocuments } from '../store/slices/documentSlice';
import { askAI, executeSuggestedAction } from '../services/ai.service';
import { getProjects } from '../services/project.service';
import { getDocuments } from '../services/document.service';
import { Bot, X, ChevronUp, ChevronDown, Send, File, Folder, MoreHorizontal } from 'lucide-react';
import { Project } from '../types';
import { Document } from '../types/document';

const useAppDispatch = () => useDispatch<AppDispatch>();

const FloatingAIAssistant = () => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(true);
  const [question, setQuestion] = useState('');
  const [conversation, setConversation] = useState<{ role: 'user' | 'assistant', content: string }[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showProjectDropdown, setShowProjectDropdown] = useState(false);
  const [showDocumentDropdown, setShowDocumentDropdown] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);
  const [filteredDocuments, setFilteredDocuments] = useState<Document[]>([]);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [selectedDocuments, setSelectedDocuments] = useState<Document[]>([]);
  const { projects } = useSelector((state: RootState) => state.project);
  const documents = useSelector((state: RootState) => state.document.documents);
  const chatRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const currentProject = useSelector((state: RootState) => state.project.currentProject);
  const { currentUser } = useSelector((state: RootState) => state.user);
  const tasks = useSelector((state: RootState) => state.task.tasks);
  const selectedOrganization = useSelector((state: RootState) => state.organization.selectedOrganization);

  const [isSelectingContext, setIsSelectingContext] = useState(false);
  const [showContextDetails, setShowContextDetails] = useState(false);

  useEffect(() => {
    if (currentUser && selectedOrganization) {
      fetchProjectsAndDocuments();
    }
  }, [currentUser, selectedOrganization]);

  const fetchProjectsAndDocuments = useCallback(async () => {
    if (currentUser && selectedOrganization) {
      try {
        const fetchedProjects = await getProjects(selectedOrganization.id);
        dispatch(setProjects(fetchedProjects));

        // Fetch documents for the organization and all projects
        const orgDocs = await getDocuments(selectedOrganization.id);
        const projectDocs = await Promise.all(
          fetchedProjects.map(project => getDocuments(selectedOrganization.id, project.id))
        );
        const allDocs = [...orgDocs, ...projectDocs.flat()];
        dispatch(setDocuments(allDocs));
      } catch (error) {
        console.error('Error fetching projects and documents:', error);
      }
    }
  }, [currentUser, selectedOrganization, dispatch]);

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [conversation]);

  useEffect(() => {
    if (currentProject && !selectedProject) {
      setSelectedProject(currentProject);
    }
  }, [currentProject, selectedProject]);

  const getContext = useCallback(() => {
    const project = selectedProject || currentProject;
    let context = '';

    if (project) {
      const projectTasks = tasks.filter(t => t.projectId === project.id);
      
      const taskDetails = projectTasks.map(task => `
        - Task: ${task.title}
          Description: ${task.description || 'No description provided'}
          Status: ${task.status}
          Due Date: ${task.dueDate ? new Date(task.dueDate).toLocaleDateString() : 'No due date set'}
      `).join('\n');
  
      context += `
Project Context:
Name: ${project.name}
Description: ${project.description || 'No description provided'}

Tasks:
${taskDetails}
      `.trim();
    }

    if (selectedDocuments.length > 0) {
      context += '\n\nReferenced Documents:\n';
      selectedDocuments.forEach(doc => {
        context += `
Document: ${doc.name}
Content: ${doc.content}
        `.trim() + '\n';
      });
    }

    return context || 'No context provided. How can I assist you?';
  }, [selectedProject, currentProject, tasks, selectedDocuments]);
  const toggleContextDetails = () => {
    setShowContextDetails(!showContextDetails);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuestion(value);

    if (value.includes('@') || value.includes('#')) {
      setIsSelectingContext(true);
    } else {
      setIsSelectingContext(false);
    }

    if (value.includes('@')) {
      const query = value.split('@').pop()?.toLowerCase() || '';
      const filtered = projects.filter(p => p.name.toLowerCase().includes(query));
      setFilteredProjects(filtered);
      setShowProjectDropdown(true);
      setShowDocumentDropdown(false);
    } else if (value.includes('#')) {
      const query = value.split('#').pop()?.toLowerCase() || '';
      const filtered = documents.filter(d => d.name.toLowerCase().includes(query));
      setFilteredDocuments(filtered);
      setShowDocumentDropdown(true);
      setShowProjectDropdown(false);
    } else {
      setShowProjectDropdown(false);
      setShowDocumentDropdown(false);
    }
  };

  const handleProjectSelect = (project: Project) => {
    setSelectedProject(project);
    setShowProjectDropdown(false);
    setQuestion(question.replace(/@\w*$/, '')); // Remove the @project from the input
    inputRef.current?.focus();
    setFilteredProjects([]);
    setIsSelectingContext(false);
  };

  const handleDocumentSelect = (document: Document) => {
    setSelectedDocuments(prev => [...prev, document]);
    setShowDocumentDropdown(false);
    setQuestion(question.replace(/#\w*$/, '')); // Remove the #document from the input
    inputRef.current?.focus();
    setFilteredDocuments([]);
    setIsSelectingContext(false);
  };

  const handleUnselectProject = () => {
    setSelectedProject(null);
  };

  const handleUnselectDocument = (documentId: string) => {
    setSelectedDocuments(prev => prev.filter(doc => doc.id !== documentId));
  };

  const handleAsk = async () => {
    if (!currentUser || question.trim().length < 2) return;

    setIsLoading(true);
    setConversation(prev => [...prev, { role: 'user', content: question }]);
    setQuestion('');

    try {
      const aiResponse = await askAI(question, {
        projectId: selectedProject?.id || currentProject?.id,
        userId: currentUser.id,
        context: getContext()
      });
      setConversation(prev => [...prev, { role: 'assistant', content: aiResponse.text }]);
    } catch (error) {
      console.error('Error asking AI:', error);
      setConversation(prev => [...prev, { role: 'assistant', content: 'Sorry, I encountered an error. Please try again.' }]);
    } finally {
      setIsLoading(false);
    }
  };



  const handleClearChat = () => {
    setConversation([]);
  };

  const ContextItem = ({ icon, name, onRemove, bgColor, textColor }: { icon: React.ReactNode; name: string; onRemove: () => void; bgColor: string; textColor: string }) => (
    <div className={`flex items-center ${bgColor} ${textColor} rounded-full px-3 py-1 text-sm mr-2 mb-2 max-w-[150px] group`}>
      <div className="flex items-center overflow-hidden">
        {icon}
        <span className="ml-1 truncate" title={name}>{name}</span>
      </div>
      <button onClick={onRemove} className="ml-1 text-gray-400 hover:text-gray-600 opacity-0 group-hover:opacity-100 transition-opacity">
        <X size={14} />
      </button>
    </div>
  );

  const renderCompactContext = () => (
    <div className="flex flex-wrap items-center">
      {selectedProject && (
        <ContextItem
          icon={<Folder size={14} className="text-indigo-600 flex-shrink-0" />}
          name={selectedProject.name}
          onRemove={handleUnselectProject}
          bgColor="bg-indigo-300"
          textColor="text-white"
        />
      )}
      {selectedDocuments.slice(0, 2).map(doc => (
        <ContextItem
          key={doc.id}
          icon={<File size={14} className="text-green-600 flex-shrink-0" />}
          name={doc.name}
          onRemove={() => handleUnselectDocument(doc.id)}
          bgColor="bg-green-300"
          textColor="text-white"
        />
      ))}
      {selectedDocuments.length > 2 && (
        <button
          onClick={toggleContextDetails}
          className="flex items-center bg-indigo-100 text-white rounded-full px-3 py-1 text-sm hover:bg-indigo-200 transition-colors"
        >
          <MoreHorizontal size={14} className="mr-1" />
          <span>{selectedDocuments.length - 2} more</span>
        </button>
      )}
    </div>
  );
  const renderDetailedContext = () => (
    <div className="mt-2 space-y-2">
      {selectedProject && (
        <div className="flex items-center justify-between bg-indigo-100 text-gray-400 rounded-md p-2 shadow-sm">
          <div className="flex items-center">
            <Folder size={16} className="text-indigo-600 mr-2" />
            <span className="text-sm font-medium">{selectedProject.name}</span>
          </div>
          <button onClick={handleUnselectProject} className="text-gray-400 hover:text-gray-600">
            <X size={14} />
          </button>
        </div>
      )}
      {selectedDocuments.map(doc => (
        <div key={doc.id} className="flex items-center justify-between bg-green-100 text-gray-400 rounded-md p-2 shadow-sm">
          <div className="flex items-center">
            <File size={16} className="text-green-600 mr-2" />
            <span className="text-sm font-medium">{doc.name}</span>
          </div>
          <button onClick={() => handleUnselectDocument(doc.id)} className="text-gray-400 hover:text-gray-600">
            <X size={14} />
          </button>
        </div>
      ))}
    </div>
  );

  
  return (
    <div className={`fixed bottom-4 right-4 z-50 ${isOpen ? 'w-96' : 'w-auto'}`}>
      {isOpen && !isMinimized && (
        <div className="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col h-[600px]">
          <div className="bg-indigo-400 text-white p-4 flex justify-between items-center">
            <h3 className="text-lg font-semibold">AI Assistant</h3>
          <div className="flex space-x-2">
  <button onClick={handleClearChat} className="text-white hover:text-indigo-200" title="Clear chat">
    Clear
  </button>
  <button onClick={() => setIsMinimized(true)} className="text-white hover:text-indigo-200">
    <ChevronDown size={20} />
  </button>
  <button onClick={() => setIsOpen(false)} className="text-white hover:text-indigo-200">
    <X size={20} />
  </button>
</div>
          </div>
          
          <div className="p-3">
            {renderCompactContext()}
            {showContextDetails && renderDetailedContext()}
          </div>
          <div ref={chatRef} className="flex-1 overflow-y-auto p-4 space-y-4">
            {conversation.map((msg, index) => (
              <div key={index} className={`flex ${msg.role === 'user' ? 'justify-end' : 'justify-start'}`}>
                <div className={`max-w-3/4 p-3 rounded-lg ${msg.role === 'user' ? 'bg-indigo-100 text-indigo-800' : 'bg-gray-100 text-gray-800'}`}>
                  {msg.content}
                </div>
              </div>
            ))}
            {isLoading && (
              <div className="flex justify-center">
                <div className="inline-block p-3 rounded-lg bg-gray-100 text-gray-800">
                  Thinking...
                </div>
              </div>
            )}
          </div>

          <div className="p-4 border-t relative">
            <div className="flex space-x-2">
              <input
                ref={inputRef}
                type="text"
                value={question}
                onChange={handleInputChange}
                placeholder="Ask a question... (@ for projects, # for documents)"
                className="flex-1 p-2 border rounded-md"
                onKeyPress={(e) => e.key === 'Enter' && handleAsk()}
              />
              <button
                onClick={handleAsk}
                disabled={isLoading || !question.trim()}
                className="bg-indigo-600 text-white p-2 rounded-md hover:bg-indigo-700 transition duration-300 disabled:opacity-50"
              >
                <Send size={20} />
              </button>
            </div>
            {isSelectingContext && (showProjectDropdown || showDocumentDropdown) && (
              <div className="absolute bottom-full left-0 w-full bg-white border rounded-md shadow-lg max-h-40 overflow-y-auto mb-1">
                {showProjectDropdown && (
                  filteredProjects.length > 0 ? (
                    filteredProjects.map(project => (
                      <div
                        key={project.id}
                        className="p-2 hover:bg-gray-100 cursor-pointer flex items-center"
                        onClick={() => handleProjectSelect(project)}
                      >
                        <Folder size={16} className="mr-2 text-indigo-600" />
                        {project.name}
                      </div>
                    ))
                  ) : (
                    <div className="p-2">No matching projects found</div>
                  )
                )}
                {showDocumentDropdown && (
                  filteredDocuments.length > 0 ? (
                    filteredDocuments.map(document => (
                      <div
                        key={document.id}
                        className="p-2 hover:bg-gray-100 cursor-pointer flex items-center"
                        onClick={() => handleDocumentSelect(document)}
                      >
                        <File size={16} className="mr-2 text-green-600" />
                        {document.name}
                      </div>
                    ))
                  ) : (
                    <div className="p-2">No matching documents found</div>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {isOpen && isMinimized && (
        <div className="bg-indigo-600 text-white p-4 rounded-t-lg shadow-lg flex justify-between items-center">
          <h3 className="text-lg font-semibold">AI Assistant</h3>
          <div className="flex space-x-2">
            <button onClick={() => setIsMinimized(false)} className="text-white hover:text-indigo-200">
              <ChevronUp size={20} />
            </button>
            <button onClick={() => setIsOpen(false)} className="text-white hover:text-indigo-200">
              <X size={20} />
            </button>
          </div>
        </div>
      )}
      {!isOpen && (
        <button
          onClick={() => { setIsOpen(true); setIsMinimized(false); }}
          className="bg-indigo-600 text-white p-4 rounded-full shadow-lg hover:bg-indigo-700 transition duration-300"
        >
          <Bot size={24} />
        </button>
      )}
    </div>
  );
};

export default FloatingAIAssistant;