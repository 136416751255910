import { getFirestore, collection, addDoc, updateDoc, doc, deleteDoc, getDocs, query, where, getDoc, arrayUnion, writeBatch } from 'firebase/firestore'; // Correct import for writeBatch

import { Timestamp } from 'firebase/firestore';

import { Task, CreateTaskDTO, UpdateTaskDTO, TaskStatus } from '../types';

const db = getFirestore(); // Initialize Firestore instance

export const createTask = async (taskData: CreateTaskDTO): Promise<Task> => {
  try {
    const createdAt = new Date();
    const updatedAt = new Date();
    const docRef = await addDoc(collection(db, 'tasks'), {
      ...taskData,
      createdAt,
      updatedAt,
      status: taskData.status as TaskStatus || 'todo' as TaskStatus,
      description: taskData.description || '',
      documentId: taskData.documentId || null,
      documentName: taskData.documentName || null,
    });
    return { 
      id: docRef.id, 
      ...taskData, 
      createdAt, 
      updatedAt, 
      status: taskData.status as TaskStatus || 'todo' as TaskStatus, 
      description: taskData.description || '',
      documentId: taskData.documentId,
      documentName: taskData.documentName,
    };
  } catch (error) {
    console.error('Error creating task:', error);
    throw error;
  }
};

export const updateTask = async (taskId: string, updateData: UpdateTaskDTO): Promise<Task> => {
  try {
    const taskRef = doc(db, 'tasks', taskId);
    await updateDoc(taskRef, { 
      ...updateData, 
      updatedAt: new Date(),
      documentId: updateData.documentId || null,
      documentName: updateData.documentName || null,
    });
    const updatedTask = await getTask(taskId);
    if (!updatedTask) throw new Error('Task not found after update');
    return updatedTask;
  } catch (error) {
    console.error('Error updating task:', error);
    throw error;
  }
};


export const getTask = async (taskId: string): Promise<Task | null> => {
  try {
    const taskDocRef = doc(db, 'tasks', taskId);
    const taskDoc = await getDoc(taskDocRef);
    if (taskDoc.exists()) {
      const data = taskDoc.data();
      return { 
        id: taskDoc.id, 
        ...data, 
        createdAt: (data.createdAt instanceof Timestamp) ? data.createdAt.toDate() : data.createdAt, 
        updatedAt: (data.updatedAt instanceof Timestamp) ? data.updatedAt.toDate() : data.updatedAt 
      } as Task;
    }
    return null;
  } catch (error) {
    console.error('Error getting task:', error);
    throw error;
  }
};


export const deleteTask = async (taskId: string): Promise<void> => {
  try {
    await deleteDoc(doc(db, 'tasks', taskId));
  } catch (error) {
    console.error('Error deleting task:', error);
    throw error;
  }
};

export const getTasks = async (projectId: string): Promise<Task[]> => {
  try {
    const q = query(collection(db, 'tasks'), where('projectId', '==', projectId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => {
      const data = doc.data();
      return { 
        id: doc.id, 
        ...data, 
        createdAt: (data.createdAt instanceof Timestamp) ? data.createdAt.toDate() : data.createdAt, 
        updatedAt: (data.updatedAt instanceof Timestamp) ? data.updatedAt.toDate() : data.updatedAt,
        dueDate: (data.dueDate instanceof Timestamp) ? data.dueDate.toDate() : data.dueDate // Ensure dueDate is also converted
      } as Task;
    });
  } catch (error) {
    console.error('Error getting tasks:', error);
    throw error;
  }
};

export const addUserToTasks = async (projectId: string, userId: string): Promise<void> => {
  const q = query(collection(db, 'tasks'), where('projectId', '==', projectId));
  const querySnapshot = await getDocs(q);
  const batch = writeBatch(db); // Use writeBatch instead of db.batch()
  querySnapshot.docs.forEach(taskDoc => {
    const taskRef = doc(db, 'tasks', taskDoc.id);
    batch.update(taskRef, {
      members: arrayUnion(userId),
    });
  });
  await batch.commit();
};