import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Task } from '../../types/task';

interface TaskState {
    tasks: Task[];
    currentTask: Task | null;
}

const initialState: TaskState = {
    tasks: [],
    currentTask: null,
};

const parseTask = (task: Task): Task => ({
    ...task,
    createdAt: new Date(task.createdAt),
    updatedAt: new Date(task.updatedAt),
    dueDate: task.dueDate ? new Date(task.dueDate) : undefined,
});

const taskSlice = createSlice({
    name: 'task',
    initialState,
    reducers: {
        setTasks(state, action: PayloadAction<Task[]>) {
            state.tasks = action.payload.map(parseTask);
        },
        addTask(state, action: PayloadAction<Task>) {
            const newTask = parseTask(action.payload);
            state.tasks.push(newTask);
        },
        updateTask(state, action: PayloadAction<Task>) {
            const updatedTask = parseTask(action.payload);
            const index = state.tasks.findIndex(task => task.id === updatedTask.id);
            if (index !== -1) {
                state.tasks[index] = updatedTask;
            }
            if (state.currentTask && state.currentTask.id === updatedTask.id) {
                state.currentTask = updatedTask;
            }
        },
        removeTask(state, action: PayloadAction<string>) {
            state.tasks = state.tasks.filter(task => task.id !== action.payload);
            if (state.currentTask && state.currentTask.id === action.payload) {
                state.currentTask = null;
            }
        },
        updateTaskDocument(state, action: PayloadAction<{ taskId: string; documentId: string; documentName: string }>) {
            const { taskId, documentId, documentName } = action.payload;
            const taskIndex = state.tasks.findIndex(task => task.id === taskId);
            if (taskIndex !== -1) {
                state.tasks[taskIndex] = {
                    ...state.tasks[taskIndex],
                    documentId,
                    documentName,
                    updatedAt: new Date(),
                };
            }
            if (state.currentTask && state.currentTask.id === taskId) {
                state.currentTask = {
                    ...state.currentTask,
                    documentId,
                    documentName,
                    updatedAt: new Date(),
                };
            }
        },
        removeTaskDocument(state, action: PayloadAction<string>) {
            const taskIndex = state.tasks.findIndex(task => task.id === action.payload);
            if (taskIndex !== -1) {
                const { documentId, documentName, ...rest } = state.tasks[taskIndex];
                state.tasks[taskIndex] = {
                    ...rest,
                    updatedAt: new Date(),
                };
            }
            if (state.currentTask && state.currentTask.id === action.payload) {
                const { documentId, documentName, ...rest } = state.currentTask;
                state.currentTask = {
                    ...rest,
                    updatedAt: new Date(),
                };
            }
        },
        setCurrentTask(state, action: PayloadAction<Task>) {
            const task = parseTask(action.payload);
            state.currentTask = task;
        },
    },
});

export const { 
    setTasks, 
    addTask, 
    updateTask, 
    removeTask, 
    updateTaskDocument, 
    removeTaskDocument,
    setCurrentTask
} = taskSlice.actions;

export default taskSlice.reducer;