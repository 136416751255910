import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { inviteUserToOrganization } from '../services/organization.service';
import { setError } from '../store/slices/organizationSlice';
import { RootState } from '../store';
import { Organization } from '../types/organization';
import { getUserByEmail } from '../services/user.service';

const InviteUserForm: React.FC<{ initialOrganizationId?: string }> = ({ initialOrganizationId }) => {
  const [email, setEmail] = useState('');
  const [selectedOrgId, setSelectedOrgId] = useState(initialOrganizationId || '');
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const dispatch = useDispatch();
  const { organizations } = useSelector((state: RootState) => state.organization);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setSuccessMessage('');

    try {
      const user = await getUserByEmail(email);
      if (!user) throw new Error('User not found');
      await inviteUserToOrganization(selectedOrgId, user.id);
      setEmail('');
      setSuccessMessage('Invitation sent successfully!');
    } catch (error) {
      dispatch(setError((error as Error).message));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      {successMessage && <p className="text-green-600">{successMessage}</p>}
      <form onSubmit={handleSubmit} className="space-y-4">
        {!initialOrganizationId && (
          <div>
            <label htmlFor="organization" className="block text-sm font-medium text-gray-700">
              Select Organization
            </label>
            <select
              id="organization"
              value={selectedOrgId}
              onChange={(e) => setSelectedOrgId(e.target.value)}
              required
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value="">Select an organization</option>
              {organizations.map((org: Organization) => (
                <option key={org.id} value={org.id}>
                  {org.name}
                </option>
              ))}
            </select>
          </div>
        )}
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Invite by Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter user email"
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          disabled={isLoading}
        >
          {isLoading ? 'Sending...' : 'Send Invitation'}
        </button>
      </form>
    </div>
  );
};

export default InviteUserForm;
