// /slices/projectSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Project } from '../../types/project';

interface ProjectState {
    projects: Project[];
    currentProject: Project | null;
}

const initialState: ProjectState = {
    projects: [],
    currentProject: null,
};

const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        setProjects(state, action: PayloadAction<Project[]>) {
            state.projects = action.payload.map(project => ({
                ...project,
                createdAt: new Date(project.createdAt),
                updatedAt: new Date(project.updatedAt),
            }));
        },
        addProject(state, action: PayloadAction<Project>) {
            const project = action.payload;
            state.projects.push({
                ...project,
                createdAt: new Date(project.createdAt),
                updatedAt: new Date(project.updatedAt),
            });
        },
        setCurrentProject(state, action: PayloadAction<Project>) {
            const project = action.payload;
            state.currentProject = {
                ...project,
                createdAt: new Date(project.createdAt),
                updatedAt: new Date(project.updatedAt),
            };
        },
        clearCurrentProject(state) {
            state.currentProject = null;
        },
    },
});

export const { setProjects, addProject, setCurrentProject, clearCurrentProject } = projectSlice.actions;
export default projectSlice.reducer;
