import { db } from '../firebase';
import { doc, addDoc, setDoc, updateDoc, arrayUnion, serverTimestamp, collection, query, where, getDocs, Timestamp, getDoc } from 'firebase/firestore';
import { OrganizationMember, CreateOrganizationDTO, Organization, Invitation } from '../types/organization';
import { fetchUserFromFirestore } from './user.service'; // Ensure you have a service to fetch user details by ID


export const createOrganization = async (data: CreateOrganizationDTO): Promise<Organization> => {
  const ownerMember: OrganizationMember = {
    userId: data.ownerId,
    role: 'admin',
    username: data.ownerUsername,
  };

  const newOrg: Omit<Organization, 'id'> = {
    name: data.name,
    ownerId: data.ownerId,
    members: [ownerMember],
    memberIds: [data.ownerId], // Add member IDs array
    createdAt: new Date(),
    updatedAt: new Date(),
  };

  const orgRef = await addDoc(collection(db, 'organizations'), {
    ...newOrg,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });

  return {
    id: orgRef.id,
    ...newOrg,
  };
};


export const getOrganizations = async (userId: string): Promise<Organization[]> => {
  try {
    const orgsRef = collection(db, 'organizations');

    const ownerQuery = query(orgsRef, where('ownerId', '==', userId));
    const memberQuery = query(orgsRef, where('memberIds', 'array-contains', userId)); // Query by memberIds

    const [ownerSnapshot, memberSnapshot] = await Promise.all([
      getDocs(ownerQuery),
      getDocs(memberQuery)
    ]);

    const organizationMap = new Map<string, Organization>(); // Use a Map to ensure uniqueness

    // Process the ownerSnapshot and add organizations to the map
    ownerSnapshot.forEach(doc => {
      organizationMap.set(doc.id, { id: doc.id, ...doc.data() } as Organization);
    });

    // Process the memberSnapshot and add organizations to the map
    memberSnapshot.forEach(doc => {
      organizationMap.set(doc.id, { id: doc.id, ...doc.data() } as Organization);
    });

    // Convert the map back to an array and return the organizations
    const organizations = Array.from(organizationMap.values());
    
    console.log('Organizations from Firestore:', organizations);
    return organizations;
  } catch (error) {
    console.error('Error fetching organizations:', error);
    return [];
  }
};





 // Adding a new method to invite a user
 export const inviteUserToOrganization = async (organizationId: string, userId: string) => {
  const invitationRef = doc(collection(db, 'invitations'));

  const newInvitation: Invitation = {
      id: invitationRef.id,
      organizationId,
      userId, // Ensure this is the UID, not the email
      status: 'pending',
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
  };

  await setDoc(invitationRef, newInvitation);
  return newInvitation;
};


// Accepting the invitation and adding the user to the organization
export const acceptOrganizationInvitation = async (invitationId: string) => {
  const invitationRef = doc(db, 'invitations', invitationId);
  const invitationDoc = await getDoc(invitationRef);

  if (!invitationDoc.exists()) throw new Error('Invitation not found');
  
  const invitation = invitationDoc.data() as Invitation;
  if (invitation.status !== 'pending') throw new Error('Invitation already processed');

  const user = await fetchUserFromFirestore(invitation.userId);
  if (!user) throw new Error('User not found');

  const orgRef = doc(db, 'organizations', invitation.organizationId);
  
  const newMember: OrganizationMember = {
    userId: invitation.userId,
    role: 'member',
    username: user.username,
  };

  await updateDoc(orgRef, {
    members: arrayUnion(newMember),
    memberIds: arrayUnion(invitation.userId), // Add the user ID to memberIds
    updatedAt: serverTimestamp(),
  });

  await updateDoc(invitationRef, { status: 'accepted', updatedAt: serverTimestamp() });

  return { success: true };
};




// Add a user to an organization with a specific role
export const addUserToOrganization = async (organizationId: string, userId: string, role: 'admin' | 'member', username: string) => {
  const organizationRef = doc(db, 'organizations', organizationId);
  const memberData: OrganizationMember = {
    userId,
    role,
    username // Add the username property
  };

  // Add the member to the organization
  await updateDoc(organizationRef, {
    members: arrayUnion(memberData),
    updatedAt: serverTimestamp()
  });
};

export const getInvitations = async (userId: string): Promise<Invitation[]> => {
  try {
    const invitationsRef = collection(db, 'invitations');
    const q = query(invitationsRef, where('userId', '==', userId), where('status', '==', 'pending'));
    const querySnapshot = await getDocs(q);

    const invitations: Invitation[] = querySnapshot.docs.map(doc => {
      const data = doc.data();

      // Ensure all required fields are mapped correctly
      return {
        id: doc.id,
        organizationId: data.organizationId,
        userId: data.userId,
        status: data.status,
        createdAt: (data.createdAt as Timestamp).toDate(),
        updatedAt: (data.updatedAt as Timestamp).toDate(),
      } as Invitation;
    });

    console.log('Fetched Invitations from Firestore:', invitations); // Debug output

    return invitations;
  } catch (error) {
    console.error('Error fetching invitations:', error);
    return [];
  }
};

// Function to get members of a specific organization
export const getOrganizationMembers = async (organizationId: string): Promise<OrganizationMember[]> => {
  try {
    const orgRef = doc(db, 'organizations', organizationId);
    const orgDoc = await getDoc(orgRef);

    if (!orgDoc.exists()) {
      throw new Error('Organization not found');
    }

    const organization = orgDoc.data() as Organization;
    return organization.members.map(member => ({
      ...member,
      username: member.username || 'Unknown', // Ensure username is included
    }));
  } catch (error) {
    console.error('Error fetching organization members:', error);
    return [];
  }
};
