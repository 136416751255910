import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { setCurrentProject, clearCurrentProject } from '../store/slices/projectSlice';
import { setTasks, addTask, updateTask as updateTaskAction, removeTask } from '../store/slices/taskSlice';
import { setDocuments, setSelectedDocument } from '../store/slices/documentSlice';
import { getProject, updateProject, deleteProject } from '../services/project.service';
import { getTasks, createTask, updateTask as updateTaskService, deleteTask } from '../services/task.service';
import { getDocuments } from '../services/document.service';
import { getOrganizationMembers } from '../services/organization.service';
import Layout from '../components/Layout';
import TaskBoard from '../components/TaskBoard';
import TaskForm from '../components/TaskForm';
import DocumentList from '../components/DocumentList';
import DocumentUpload from '../components/DocumentUpload';
import DocumentViewer from '../components/DocumentViewer';
import { Task, Project } from '../types';
import { Document } from '../types/document';
import { Edit2, Trash2, PlusCircle, FileText, Users, Clock, BarChart2 } from 'lucide-react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { TaskStatus } from '../types/task';

const ProjectDetails: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentProject = useSelector((state: RootState) => state.project.currentProject);
  const tasks = useSelector((state: RootState) => state.task.tasks);
  const documents = useSelector((state: RootState) => state.document.documents);
  const selectedOrganization = useSelector((state: RootState) => state.organization.selectedOrganization);
  const currentUser = useSelector((state: RootState) => state.user.currentUser);

  const [showTaskForm, setShowTaskForm] = useState(false);
  const [editingTask, setEditingTask] = useState<Task | null>(null);
  const [showDocumentUpload, setShowDocumentUpload] = useState(false);
  const [activeSection, setActiveSection] = useState<'overview' | 'tasks' | 'documents'>('overview');
  const [organizationMembers, setOrganizationMembers] = useState<string[]>([]);
  const [viewingDocument, setViewingDocument] = useState<Document | null>(null);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      if (projectId && selectedOrganization) {
        try {
          const project = await getProject(projectId);
          if (project) {
            dispatch(setCurrentProject(project));
            const fetchedTasks = await getTasks(projectId);
            dispatch(setTasks(fetchedTasks));
            const fetchedDocuments = await getDocuments(selectedOrganization.id, projectId);
            dispatch(setDocuments(fetchedDocuments));
            const members = await getOrganizationMembers(selectedOrganization.id);
            console.log("Members:", members);
            console.log("Selected Organization:", selectedOrganization.id);

            setOrganizationMembers(members.map(member => member.username));
          } else {
            navigate('/dashboard');
          }
        } catch (error) {
          console.error('Error fetching project details:', error);
          navigate('/dashboard');
        }
      }
    };

    fetchProjectDetails();

    return () => {
      dispatch(clearCurrentProject());
    };
  }, [projectId, selectedOrganization, dispatch, navigate]);

  const handleUpdateProject = async (projectData: Partial<Project>) => {
    if (currentProject && currentProject.id) {
      try {
        const updatedProject = await updateProject(currentProject.id, projectData);
        dispatch(setCurrentProject(updatedProject));
      } catch (error) {
        console.error('Error updating project:', error);
      }
    }
  };

  const handleDeleteProject = async () => {
    if (currentProject && window.confirm('Are you sure you want to delete this project?')) {
      try {
        await deleteProject(currentProject.id);
        navigate('/dashboard');
      } catch (error) {
        console.error('Error deleting project:', error);
      }
    }
  };

  const handleCreateTask = async (taskData: Partial<Task>) => {
    if (currentProject) {
      try {
        const newTask = await createTask({
          ...taskData,
          projectId: currentProject.id,
        } as Task);
        dispatch(addTask(newTask));
        setShowTaskForm(false);
      } catch (error) {
        console.error('Error creating task:', error);
      }
    }
  };

  const handleUpdateTask = async (taskData: Partial<Task>) => {
    if (editingTask) {
      try {
        const updatedTask = await updateTaskService(editingTask.id, taskData);
        dispatch(updateTaskAction(updatedTask));
        setEditingTask(null);
        setShowTaskForm(false);
      } catch (error) {
        console.error('Error updating task:', error);
      }
    }
  };

  const handleEditTask = (task: Task) => {
    setEditingTask(task);
    setShowTaskForm(true);
  };

  const handleDeleteTask = async (taskId: string) => {
    if (window.confirm('Are you sure you want to delete this task?')) {
      try {
        await deleteTask(taskId);
        dispatch(removeTask(taskId));
      } catch (error) {
        console.error('Error deleting task:', error);
      }
    }
  };
  const handleViewDocument = async (documentId: string, documentName: string) => {
    if (selectedOrganization && currentProject) {
      try {
        const documents = await getDocuments(selectedOrganization.id, currentProject.id);
        const document = documents.find(doc => doc.id === documentId);
        if (document) {
          setViewingDocument(document);
        } else {
          console.error(`Document not found: ${documentName} (ID: ${documentId})`);
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    }
  };

  const getTaskDistributionData = () => {
    const statusCounts = tasks.reduce((acc, task) => {
      acc[task.status] = (acc[task.status] || 0) + 1;
      return acc;
    }, {} as Record<TaskStatus, number>);
  
    return Object.entries(statusCounts).map(([status, count]) => ({
      name: status.charAt(0).toUpperCase() + status.slice(1),
      value: count
    }));
  };
  
  const handleSelectTask = (taskId: string) => {
    navigate(`/tasks/${taskId}`);
  };


  if (!currentProject || !selectedOrganization) {
    return (
      <Layout>
        <div className="flex items-center justify-center h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500"></div>
        </div>
      </Layout>
    );
  }
 return (
  <Layout>
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      {/* Project Header */}
      <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
        <div className="bg-gradient-to-r from-indigo-600 to-blue-500 px-6 py-4">
          <div className="flex justify-between items-center">
            <h1 className="text-3xl font-bold text-white">{currentProject.name}</h1>
            <div className="flex space-x-2">
              <button
                onClick={() => {
                  const newName = prompt('Enter new project name:', currentProject.name);
                  if (newName) handleUpdateProject({ name: newName });
                }}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-200 bg-indigo-700 hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
              >
                <Edit2 className="h-4 w-4 mr-2" />
                Edit
              </button>
              <button
                onClick={handleDeleteProject}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-red-200 bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors duration-200"
              >
                <Trash2 className="h-4 w-4 mr-2" />
                Delete
              </button>
            </div>
          </div>
        </div>
        <div className="px-6 py-4">
          <p className="text-gray-600 mb-4">{currentProject.description}</p>
          <div className="flex items-center text-sm text-gray-500">
            <Users className="h-5 w-5 mr-2" />
            <span>
              Team: {organizationMembers.length > 0
                ? organizationMembers.join(', ')
                : 'No members assigned'}
            </span>
          </div>
        </div>
      </div>

      {/* Section Tabs */}
      <div className="flex border-b border-gray-200 mb-6">
        <button
          className={`py-4 px-6 text-sm font-medium ${
            activeSection === 'overview'
              ? 'text-indigo-600 border-b-2 border-indigo-600'
              : 'text-gray-500 hover:text-gray-700'
          } transition-colors duration-200`}
          onClick={() => setActiveSection('overview')}
        >
          Overview
        </button>
        <button
          className={`py-4 px-6 text-sm font-medium ${
            activeSection === 'tasks'
              ? 'text-indigo-600 border-b-2 border-indigo-600'
              : 'text-gray-500 hover:text-gray-700'
          } transition-colors duration-200`}
          onClick={() => setActiveSection('tasks')}
        >
          Tasks
        </button>
        <button
          className={`py-4 px-6 text-sm font-medium ${
            activeSection === 'documents'
              ? 'text-indigo-600 border-b-2 border-indigo-600'
              : 'text-gray-500 hover:text-gray-700'
          } transition-colors duration-200`}
          onClick={() => setActiveSection('documents')}
        >
          Documents
        </button>
      </div>

      {/* Overview Section */}
      {activeSection === 'overview' && (
        <div className="mb-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Project Statistics */}
            <div className="bg-white shadow rounded-lg p-6">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Project Statistics</h2>
              <div className="grid grid-cols-2 gap-4">
                <div
                  className="bg-blue-100 p-4 rounded-lg cursor-pointer"
                  onClick={() => setActiveSection('tasks')}
                >
                  <p className="text-sm text-blue-800">Total Tasks</p>
                  <p className="text-2xl font-semibold text-blue-900">{tasks.length}</p>
                </div>
                <div
                  className="bg-green-100 p-4 rounded-lg cursor-pointer"
                  onClick={() => setActiveSection('tasks')}
                >
                  <p className="text-sm text-green-800">Completed Tasks</p>
                  <p className="text-2xl font-semibold text-green-900">{tasks.filter(t => t.status === 'done').length}</p>
                </div>
                <div
                  className="bg-yellow-100 p-4 rounded-lg cursor-pointer"
                  onClick={() => setActiveSection('tasks')}
                >
                  <p className="text-sm text-yellow-800">In Progress</p>
                  <p className="text-2xl font-semibold text-yellow-900">{tasks.filter(t => t.status === 'inProgress').length}</p>
                </div>
                <div
                  className="bg-red-100 p-4 rounded-lg cursor-pointer"
                  onClick={() => setActiveSection('tasks')}
                >
                  <p className="text-sm text-red-800">To Do</p>
                  <p className="text-2xl font-semibold text-red-900">{tasks.filter(t => t.status === 'todo').length}</p>
                </div>
              </div>
            </div>

            {/* Task Distribution Chart */}
            <div className="bg-white shadow rounded-lg p-6">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Task Distribution</h2>
              <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={getTaskDistributionData()}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {getTaskDistributionData().map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={['#0088FE', '#00C49F', '#FFBB28'][index % 3]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>

          {/* Timeline */}
          <div className="mt-8 bg-white shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Upcoming Deadlines</h2>
            <div className="space-y-4">
              {tasks
                .filter(task => task.dueDate && new Date(task.dueDate) > new Date())
                .sort((a, b) => new Date(a.dueDate!).getTime() - new Date(b.dueDate!).getTime())
                .slice(0, 5)
                .map((task, index) => (
                  <div key={index} className="flex items-center">
                    <div className="flex-shrink-0">
                      <Clock className="h-5 w-5 text-gray-400" />
                    </div>
                    <div className="ml-4">
                      <p className="text-sm font-medium text-gray-900">{task.title}</p>
                      <p className="text-sm text-gray-500">{new Date(task.dueDate!).toLocaleDateString()}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}

      {/* Tasks Section */}
      {activeSection === 'tasks' && (
        <div className="mb-8">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold text-gray-900">Tasks</h2>
            <button
              onClick={() => {
                setEditingTask(null);
                setShowTaskForm(!showTaskForm);
              }}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <PlusCircle className="h-5 w-5 mr-2" />
              {showTaskForm ? 'Cancel' : 'Add Task'}
            </button>
          </div>
          {showTaskForm && (
            <div className="mb-6 bg-gray-50 p-4 rounded-lg">
              <TaskForm
                task={editingTask || undefined}
                projectId={currentProject.id}
                onSubmit={editingTask ? handleUpdateTask : handleCreateTask}
                onCancel={() => {
                  setShowTaskForm(false);
                  setEditingTask(null);
                }}
              />
            </div>
          )}
         <TaskBoard
              tasks={tasks}
              onEditTask={handleEditTask}
              onDeleteTask={handleDeleteTask}
              onViewDocument={handleViewDocument}
              onSelectTask={handleSelectTask} // Add this line
            />
        </div>
      )}

      {/* Documents Section */}
      {activeSection === 'documents' && (
        <div className="mb-8">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold text-gray-900">Project Documents</h2>
            <button
              onClick={() => setShowDocumentUpload(!showDocumentUpload)}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <FileText className="h-5 w-5 mr-2" />
              {showDocumentUpload ? 'Cancel' : 'Upload Document'}
            </button>
          </div>
          {showDocumentUpload && currentUser && selectedOrganization && (
            <div className="mb-4 bg-gray-50 p-4 rounded-lg">
              <DocumentUpload
                organizationId={selectedOrganization.id}
                projectId={currentProject.id}
                userId={currentUser.id}
              />
            </div>
          )}
          <DocumentList documents={documents} onViewDocument={handleViewDocument} />
        </div>
      )}

      {/* Document Viewer Modal */}
      {viewingDocument && (
        <DocumentViewer
          document={viewingDocument}
          onClose={() => setViewingDocument(null)}
        />
      )}
    </div>
  </Layout>
);

};

export default ProjectDetails;