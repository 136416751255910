import { auth, db } from '../firebase';
import { 
  doc, 
  getDoc, 
  setDoc, 
  updateDoc, 
  serverTimestamp, 
  Timestamp,
  collection,
  query,
  where,
  getDocs
} from 'firebase/firestore';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut as firebaseSignOut
} from 'firebase/auth';
import { User, CreateUserDTO, UpdateUserDTO, UserCredentials } from '../types/user';

// Create a new user in Firebase Auth and store additional profile data in Firestore
export const createUser = async (userData: CreateUserDTO): Promise<User> => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, userData.email, userData.password);
    const firebaseUser = userCredential.user;

    const newUser: User = {
      id: firebaseUser.uid,
      username: userData.username,
      email: userData.email,
      createdAt: new Date(),
      updatedAt: new Date()
    };

    // Store the new user in Firestore
    await setDoc(doc(db, 'users', firebaseUser.uid), {
      ...newUser,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    return newUser;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

// Sign in a user and fetch their Firestore profile
export const signIn = async (credentials: UserCredentials): Promise<User> => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, credentials.email, credentials.password);
    const firebaseUser = userCredential.user;

    const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
    if (!userDoc.exists()) {
      throw new Error('User document not found');
    }

    const userData = userDoc.data();
    return {
      id: firebaseUser.uid,
      username: userData?.username,
      email: userData?.email,
      createdAt: (userData.createdAt as Timestamp).toDate(),
      updatedAt: (userData.updatedAt as Timestamp).toDate()
    };
  } catch (error) {
    console.error('Error signing in:', error);
    throw error;
  }
};

// Get a user by their email address
export const getUserByEmail = async (email: string): Promise<User | null> => {
  const usersRef = collection(db, 'users');
  const q = query(usersRef, where('email', '==', email));
  const querySnapshot = await getDocs(q);
  
  if (querySnapshot.empty) {
    return null;
  }
  
  const userDoc = querySnapshot.docs[0];
  return { id: userDoc.id, ...userDoc.data() } as User;
};

// Sign out the currently authenticated user
export const signOut = async (): Promise<void> => {
  try {
    await firebaseSignOut(auth);
  } catch (error) {
    console.error('Error signing out:', error);
    throw error;
  }
};

// Fetch the user's profile from Firestore
export const fetchUserFromFirestore = async (userId: string): Promise<User> => {
  const userDocRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userDocRef);

  if (!userDoc.exists()) {
    throw new Error('User profile not found');
  }

  return userDoc.data() as User;
};

// Update the user's profile in Firestore
export const updateUser = async (userData: UpdateUserDTO): Promise<User> => {
  if (!auth.currentUser) throw new Error('No authenticated user');

  try {
    const userRef = doc(db, 'users', auth.currentUser.uid);
    await updateDoc(userRef, {
      ...userData,
      updatedAt: serverTimestamp(),
    });

    const updatedDoc = await getDoc(userRef);
    if (!updatedDoc.exists()) {
      throw new Error('User document not found after update');
    }

    const updatedData = updatedDoc.data();
    return {
      id: auth.currentUser.uid,
      username: updatedData.username,
      email: updatedData.email,
      createdAt: (updatedData.createdAt as Timestamp).toDate(),
      updatedAt: (updatedData.updatedAt as Timestamp).toDate()
    };
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};

// Get the currently authenticated user and fetch their profile from Firestore
export const getCurrentUser = async (): Promise<User | null> => {
  const firebaseUser = auth.currentUser;
  if (!firebaseUser) return null;

  try {
    const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
    if (!userDoc.exists()) {
      throw new Error('User document not found');
    }

    const userData = userDoc.data();
    return {
      id: firebaseUser.uid,
      username: userData?.username,
      email: userData?.email,
      createdAt: (userData.createdAt as Timestamp).toDate(),
      updatedAt: (userData.updatedAt as Timestamp).toDate()
    };
  } catch (error) {
    console.error('Error getting current user:', error);
    throw error;
  }
};

// Fetch a user by their ID
export const getUserById = async (userId: string): Promise<User | null> => {
  const userDoc = await getDoc(doc(db, 'users', userId));
  if (userDoc.exists()) {
    const userData = userDoc.data();
    return {
      id: userId,
      username: userData?.username,
      email: userData?.email,
      createdAt: (userData.createdAt as Timestamp).toDate(),
      updatedAt: (userData.updatedAt as Timestamp).toDate()
    };
  }
  return null;
};
