import React, { useState, useEffect } from 'react';
import OrganizationForm from '../components/OrganizationForm';
import InviteUserForm from '../components/InviteUserForm';
import Layout from '../components/Layout';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import type { Organization } from '../types/organization';

const OrganizationPage: React.FC = () => {
  const [createdOrgId, setCreatedOrgId] = useState<string | null>(null);
  const { organizations } = useSelector((state: RootState) => state.organization);

  useEffect(() => {
    if (organizations.length > 0 && !createdOrgId) {
      setCreatedOrgId(organizations[0].id);
    }
  }, [organizations]);

  const handleOrganizationCreated = (orgId: string) => {
    setCreatedOrgId(orgId);
  };

  return (
    <Layout>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-2xl font-bold mb-4">Organization Management</h1>

        {/* Section for Creating an Organization */}
        <div className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Create Organization</h2>
          <OrganizationForm onOrganizationCreated={handleOrganizationCreated} />
        </div>


        {/* Option to invite users after creating an organization */}
        {organizations.length > 0 && (
          <div className="mb-8">
            <h2 className="text-xl font-semibold mb-2">Your Organizations</h2>
            <ul className="space-y-4">
              {organizations.map((org: Organization) => (
                <li key={org.id} className="border p-4 rounded-md">
                  <h3 className="text-lg font-bold">{org.name}</h3>
                  <InviteUserForm initialOrganizationId={org.id} />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default OrganizationPage