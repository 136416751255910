import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState, AppDispatch } from '../store';
import { setProjects, addProject } from '../store/slices/projectSlice';
import { setDocuments } from '../store/slices/documentSlice';
import { setTasks } from '../store/slices/taskSlice';
import { Task } from '../types';
import { getProjects, createProject } from '../services/project.service';
import { getTasks } from '../services/task.service';
import { getOrganizations } from '../services/organization.service';
import { getDocuments } from '../services/document.service';
import Layout from '../components/Layout';
import { PlusIcon, ChartBarIcon, ClockIcon, CheckCircleIcon, ChevronRightIcon, FileIcon, FileTextIcon, ImageIcon } from 'lucide-react';
import { Project, ProjectWithTaskCounts, CreateProjectDTO } from '../types/project';
import ProjectForm from '../components/ProjectForm';
import { Organization } from '../types/organization';
import DocumentViewer from '../components/DocumentViewer';
import { Document } from '../types/document';
import DocumentUpload from '../components/DocumentUpload';
import { UpdateProjectDTO } from '../types/project';
import { setSelectedOrganization } from '../store/slices/organizationSlice';

const Dashboard: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { currentUser } = useSelector((state: RootState) => state.user);
  const { projects } = useSelector((state: RootState) => state.project);
  const tasks = useSelector((state: RootState) => state.task.tasks);
  const [projectsWithCounts, setProjectsWithCounts] = useState<ProjectWithTaskCounts[]>([]);
  const [recentActivity, setRecentActivity] = useState<(Task | Project)[]>([]);
  const [showProjectForm, setShowProjectForm] = useState(false);
  const [showAllProjects, setShowAllProjects] = useState(false);
  const [showAllActivity, setShowAllActivity] = useState(false);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [selectedOrg, setSelectedOrg] = useState<string | null>(null);
  const [showDocumentUpload, setShowDocumentUpload] = useState(false);
  const documents = useSelector((state: RootState) => state.document.documents);
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(null);


  useEffect(() => {
    if (currentUser) {
      const fetchOrganizations = async () => {
        try {
          const fetchedOrganizations = await getOrganizations(currentUser.id);
          setOrganizations(fetchedOrganizations);
          if (fetchedOrganizations.length > 0) {
            const firstOrganizationId = fetchedOrganizations[0].id;
            setSelectedOrg(firstOrganizationId);
            dispatch(setSelectedOrganization(fetchedOrganizations[0])); // Pass the entire organization object
          }
        } catch (error) {
          console.error('Error fetching organizations:', error);
        }
      };
      fetchOrganizations();
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    if (currentUser && selectedOrg) {
      const fetchProjectsTasksAndDocuments = async () => {
        try {
          const fetchedProjects = await getProjects(selectedOrg);
          dispatch(setProjects(fetchedProjects));
          const fetchedTasks = await Promise.all(fetchedProjects.map(project => getTasks(project.id)));
          const allTasks = fetchedTasks.flat();
          dispatch(setTasks(allTasks));
          const fetchedDocuments = await getDocuments(selectedOrg);
          dispatch(setDocuments(fetchedDocuments));
        } catch (error) {
          console.error('Error fetching projects, tasks, and documents:', error);
        }
      };
      fetchProjectsTasksAndDocuments();
    }
  }, [currentUser, selectedOrg, dispatch]);

    useEffect(() => {
    const projectsWithTaskCounts = projects.map(project => {
      const projectTasks = tasks.filter(task => task.projectId === project.id);
      return {
        ...project,
        todoCount: projectTasks.filter(task => task.status === 'todo').length,
        inProgressCount: projectTasks.filter(task => task.status === 'inProgress').length,
        doneCount: projectTasks.filter(task => task.status === 'done').length,
      };
    });
    setProjectsWithCounts(projectsWithTaskCounts);

    const activity = [...projects, ...tasks]
      .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
      .slice(0, 10);
    setRecentActivity(activity);
  }, [projects, tasks]);

  const handleCreateProject = async (projectData: CreateProjectDTO | UpdateProjectDTO) => {
    if (currentUser && selectedOrg) {
      try {
        const newProject = await createProject(selectedOrg, {
          ...(projectData as CreateProjectDTO),
          ownerId: currentUser.id,
          organizationId: selectedOrg,
        });
        dispatch(addProject(newProject));
        setShowProjectForm(false);
      } catch (error) {
        console.error('Error creating project:', error);
      }
    }
  };
  const getDocumentIcon = (fileName: string) => {
    if (fileName.match(/\.(jpg|jpeg|png|gif)$/i)) {
      return <ImageIcon className="h-6 w-6 text-blue-500" />;
    } else if (fileName.match(/\.(pdf|doc|docx)$/i)) {
      return <FileTextIcon className="h-6 w-6 text-red-500" />;
    } else {
      return <FileIcon className="h-6 w-6 text-gray-500" />;
    }
  };
  
  const truncateFileName = (fileName: string, maxLength: number) => {
    if (fileName.length <= maxLength) return fileName;
    const extension = fileName.split('.').pop();
    const nameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'));
    return `${nameWithoutExtension.substring(0, maxLength - 3)}...${extension}`;
  };
  const totalTasks = projectsWithCounts.reduce((sum, project) => sum + project.todoCount + project.inProgressCount + project.doneCount, 0);
  const completedTasks = projectsWithCounts.reduce((sum, project) => sum + project.doneCount, 0);
  const completionRate = totalTasks > 0 ? (completedTasks / totalTasks * 100).toFixed(1) : 0;

  const visibleActivity = showAllActivity ? recentActivity : recentActivity.slice(0, 5);
  const visibleProjects = showAllProjects ? projectsWithCounts : projectsWithCounts.slice(0, 5);

  return (
    <Layout>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Dashboard</h1>
        
        {/* Organization Selection */}
        <div className="mb-6">
          <label htmlFor="organization-select" className="block text-sm font-medium text-gray-700">Select Organization</label>
          <select
            id="organization-select"
            value={selectedOrg || ''}
            onChange={(e) => {
              setSelectedOrg(e.target.value);
              const selectedOrganization = organizations.find(org => org.id === e.target.value);
              if (selectedOrganization) {
                dispatch(setSelectedOrganization(selectedOrganization));
              }
            }}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            {organizations.map(org => (
              <option key={org.id} value={org.id}>{org.name}</option>
            ))}
          </select>
        </div>

        {/* Statistics */}
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 mb-6">
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
              <div className="flex items-center">
                <div className="flex-shrink-0 bg-indigo-500 rounded-md p-3">
                  <ChartBarIcon className="h-6 w-6 text-white" />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">Total Projects</dt>
                    <dd className="text-3xl font-semibold text-gray-900">{projectsWithCounts.length}</dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
              <div className="flex items-center">
                <div className="flex-shrink-0 bg-green-500 rounded-md p-3">
                  <ClockIcon className="h-6 w-6 text-white" />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">Total Tasks</dt>
                    <dd className="text-3xl font-semibold text-gray-900">{totalTasks}</dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
              <div className="flex items-center">
                <div className="flex-shrink-0 bg-red-500 rounded-md p-3">
                  <CheckCircleIcon className="h-6 w-6 text-white" />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">Overall Completion Rate</dt>
                    <dd className="text-3xl font-semibold text-gray-900">{completionRate}%</dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Recent Activity and Projects List */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
          {/* Recent Activity */}
          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Recent Activity</h2>
            <ul className="divide-y divide-gray-200">
              {visibleActivity.map((item, index) => (
                <li key={index} className="py-4">
                  <div className="flex space-x-3">
                    <div className="flex-1 space-y-1">
                      <div className="flex items-center justify-between">
                        <h3 className="text-sm font-medium">{('title' in item) ? item.title : item.name}</h3>
                        <p className="text-sm text-gray-500">{new Date(item.updatedAt).toLocaleDateString()}</p>
                      </div>
                      <p className="text-sm text-gray-500">
                        {('title' in item) ? `Task updated in ${item.projectId}` : 'Project updated'}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            {recentActivity.length > 5 && (
              <button
                onClick={() => setShowAllActivity(!showAllActivity)}
                className="mt-4 w-full text-center text-sm text-indigo-600 hover:text-indigo-800 focus:outline-none focus:underline"
              >
                {showAllActivity ? 'Show Less' : 'View All Activity'}
              </button>
            )}
          </div>

          {/* Projects List */}
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
              <h2 className="text-lg leading-6 font-medium text-gray-900">Your Projects</h2>
              <button
                onClick={() => setShowProjectForm(!showProjectForm)}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                {showProjectForm ? 'Cancel' : 'New Project'}
              </button>
            </div>
            {showProjectForm && selectedOrg && (
        <div className="px-4 py-5 sm:px-6">
          <ProjectForm 
            onSubmit={handleCreateProject}
            onCancel={() => setShowProjectForm(false)}
            organizationId={selectedOrg}
          />
              </div>
            )}
            <ul className="divide-y divide-gray-200">
              {visibleProjects.map((project) => (
                <li key={project.id}>
                  <Link to={`/projects/${project.id}`} className="block hover:bg-gray-50">
                    <div className="px-4 py-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <p className="text-sm font-medium text-indigo-600 truncate">{project.name}</p>
                        <div className="ml-2 flex-shrink-0 flex">
                          <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            {project.todoCount + project.inProgressCount + project.doneCount} tasks
                          </p>
                        </div>
                      </div>
                      <div className="mt-2 sm:flex sm:justify-between">
                        <div className="sm:flex">
                          <p className="flex items-center text-sm text-gray-500">
                            <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            {project.doneCount} / {project.todoCount + project.inProgressCount + project.doneCount} completed
                          </p>
                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                          <ClockIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                          <p>
                            Updated <time dateTime={project.updatedAt.toString()}>{new Date(project.updatedAt).toLocaleDateString()}</time>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
            {projectsWithCounts.length > 5 && (
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  onClick={() => setShowAllProjects(!showAllProjects)}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {showAllProjects ? 'Show Less' : 'View All Projects'}
                  <ChevronRightIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            )}
          </div>
        </div>

       {/* Recent Documents */}
       <div className="mt-8 bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 className="text-lg leading-6 font-medium text-gray-900">Recent Documents</h2>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Quick access to your latest files</p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
              {documents.slice(0, 5).map((doc) => (
                <div key={doc.id} className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-gray-50 cursor-pointer" onClick={() => setSelectedDocument(doc)}>
                  <dt className="text-sm font-medium text-gray-500 flex items-center">
                    {getDocumentIcon(doc.name)}
                    <span className="ml-2">{truncateFileName(doc.name, 20)}</span>
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {new Date(doc.createdAt ?? '').toLocaleDateString()} • {doc.createdBy}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
          <div className="px-4 py-3 bg-gray-50 sm:px-6 flex justify-between items-center">
            <button
              onClick={() => setShowDocumentUpload(!showDocumentUpload)}
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" />
              Upload Document
            </button>
            {documents.length > 5 && (
              <Link
                to="/documents"
                className="text-sm font-medium text-indigo-600 hover:text-indigo-500 flex items-center"
              >
                View all documents
                <ChevronRightIcon className="ml-1 h-4 w-4" />
              </Link>
            )}
          </div>
        </div>

        {/* Document Upload Modal */}
        {showDocumentUpload && selectedOrg && currentUser && (
          <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                        Upload Document
                      </h3>
                      <div className="mt-2">
                        <DocumentUpload
                          organizationId={selectedOrg}
                          userId={currentUser.id}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setShowDocumentUpload(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Document Viewer Modal */}
        {selectedDocument && (
          <DocumentViewer
            document={selectedDocument}
            onClose={() => setSelectedDocument(null)}
          />
        )}
      </div>
    </Layout>
  );
};

export default Dashboard;