import { doc, setDoc, getDoc, serverTimestamp, collection, getDocs, query, where, updateDoc, deleteDoc } from 'firebase/firestore';
import { Project, CreateProjectDTO, UpdateProjectDTO } from '../types/project';
import { addUserToTasks } from './task.service'; // Import the function to add user to tasks
import { auth } from '../firebase';
import { db } from '../firebase'; // Your Firestore initialization
import { Timestamp } from 'firebase/firestore'; // Ensure you import Timestamp if needed

export const createProject = async (organizationId: string, projectData: any) => {
  const projectRef = doc(collection(db, 'projects'));

  const newProject = {
    ...projectData,
    organizationId,
    createdBy: auth.currentUser?.uid,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  };

  await setDoc(projectRef, newProject);
  return { id: projectRef.id, ...newProject };
};

export const getProject = async (projectId: string): Promise<Project | null> => {
  try {
    const projectDocRef = doc(db, 'projects', projectId);
    const projectDoc = await getDoc(projectDocRef);
    if (projectDoc.exists()) {
      const data = projectDoc.data();
      return { 
        id: projectDoc.id, 
        ...data, 
        createdAt: data.createdAt instanceof Timestamp ? data.createdAt.toDate() : new Date(data.createdAt),
        updatedAt: data.updatedAt instanceof Timestamp ? data.updatedAt.toDate() : new Date(data.updatedAt),
      } as Project;
    }
    return null;
  } catch (error) {
    console.error('Error getting project:', error);
    throw error;
  }
};

export const updateProject = async (projectId: string, updateData: UpdateProjectDTO): Promise<Project> => {
  try {
    const projectRef = doc(db, 'projects', projectId);
    await updateDoc(projectRef, { ...updateData, updatedAt: new Date() });
    const updatedProject = await getProject(projectId);
    if (!updatedProject) throw new Error('Project not found after update');
    return updatedProject;
  } catch (error) {
    console.error('Error updating project:', error);
    throw error;
  }
};

export const deleteProject = async (projectId: string): Promise<void> => {
  try {
    await deleteDoc(doc(db, 'projects', projectId));
  } catch (error) {
    console.error('Error deleting project:', error);
    throw error;
  }
};

export const getProjects = async (organizationId: string): Promise<Project[]> => {
  const projectsRef = collection(db, 'projects');
  const q = query(projectsRef, where('organizationId', '==', organizationId));
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map(doc => {
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
      createdAt: data.createdAt instanceof Timestamp ? data.createdAt.toDate() : new Date(data.createdAt),
      updatedAt: data.updatedAt instanceof Timestamp ? data.updatedAt.toDate() : new Date(data.updatedAt),
    } as Project;
  });
};

export const getProjectsByOrganizationId = async (organizationId: string): Promise<Project[]> => {
  const q = query(collection(db, 'projects'), where('organizationId', '==', organizationId));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => {
    const data = doc.data();
    return { 
      id: doc.id, 
      ...data, 
      createdAt: data.createdAt instanceof Timestamp ? data.createdAt.toDate() : new Date(data.createdAt),
      updatedAt: data.updatedAt instanceof Timestamp ? data.updatedAt.toDate() : new Date(data.updatedAt),
    } as Project;
  });
};

export const addUserToProjectTasks = async (projectId: string, userId: string): Promise<void> => {
  await addUserToTasks(projectId, userId);
};
