import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './store';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import ProjectDetails from './pages/ProjectDetails';
import FloatingAIAssistant from './components/FloatingAIAssistant';
import OrganizationPage from './pages/Organization';
import { getOrganizations } from './services/organization.service';
import { setOrganizations } from './store/slices/organizationSlice';
import Invitations from './pages/Invitations';
import Footer from './components/Footer'; // Import the Footer component
import TaskDetails from './pages/TaskDetails';
const App: React.FC = () => {

  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: RootState) => state.user);
  useEffect(() => {
    const fetchOrganizations = async () => {
      if (currentUser) {
        try {
          const orgs = await getOrganizations(currentUser.id);
          dispatch(setOrganizations(orgs));
        } catch (error) {
          console.error('Failed to fetch organizations:', error);
        }
      }
    };

    fetchOrganizations();
  }, [currentUser, dispatch]);
  return (
    <Router>
      <div className="relative min-h-screen">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/projects/:projectId" element={<ProjectDetails />} />
          <Route path="/organization" element={<OrganizationPage />} />
          <Route path="/invitations" element={<Invitations />} />
          <Route path="/tasks/:taskId" element={<TaskDetails />} />
        </Routes>
        <Footer />
        <FloatingAIAssistant />
      </div>
    </Router>
  );
};

export default App;