import React, { useState, useEffect } from 'react';
import { Task, TaskStatus } from '../types';
import { XIcon, Upload } from 'lucide-react';
import { createDocument } from '../services/document.service';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

interface TaskFormProps {
  task?: Task;
  projectId: string;
  onSubmit: (taskData: Partial<Task>) => void;
  onCancel: () => void;
}

const TaskForm: React.FC<TaskFormProps> = ({ task, projectId, onSubmit, onCancel }) => {
  const [title, setTitle] = useState(task?.title || '');
  const [description, setDescription] = useState(task?.description || '');
  const [status, setStatus] = useState<TaskStatus>(task?.status || 'todo');
  const [dueDate, setDueDate] = useState(task?.dueDate ? new Date(task.dueDate).toISOString().split('T')[0] : '');
  const [file, setFile] = useState<File | null>(null);
  const [documentName, setDocumentName] = useState(task?.documentName || '');
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const selectedOrganization = useSelector((state: RootState) => state.organization.selectedOrganization);
  useEffect(() => {
    if (task) {
      setTitle(task.title);
      setDescription(task.description);
      setStatus(task.status);
      setDueDate(task.dueDate ? new Date(task.dueDate).toISOString().split('T')[0] : '');
      setDocumentName(task.documentName || '');
    }
  }, [task]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setDocumentName(e.target.files[0].name);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let documentId: string | undefined;

    if (file && currentUser && selectedOrganization) {
      try {
        const content = await file.text();
        const documentData = {
          name: file.name,
          content,
          organizationId: selectedOrganization.id,
          projectId,
          createdBy: currentUser.id,
        };
        const newDoc = await createDocument(documentData, currentUser.id);
        documentId = newDoc.id;
      } catch (error) {
        console.error('Error uploading document:', error);
        // Handle error (e.g., show error message to user)
      }
    }

    onSubmit({
      title,
      description,
      status,
      projectId,
      dueDate: dueDate ? new Date(dueDate) : undefined,
      documentId,
      documentName: documentName || undefined,
    });
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <div className="mb-4">
        <label htmlFor="title" className="block text-gray-700 text-sm font-bold mb-2">
          Task Title
        </label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="description" className="block text-gray-700 text-sm font-bold mb-2">
          Description
        </label>
        <textarea
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          rows={3}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        ></textarea>
      </div>
      <div className="mb-4">
        <label htmlFor="status" className="block text-gray-700 text-sm font-bold mb-2">
          Status
        </label>
        <select
          id="status"
          value={status}
          onChange={(e) => setStatus(e.target.value as TaskStatus)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        >
          <option value="todo">To Do</option>
          <option value="inProgress">In Progress</option>
          <option value="done">Done</option>
        </select>
      </div>
      <div className="mb-4">
        <label htmlFor="dueDate" className="block text-gray-700 text-sm font-bold mb-2">
          Due Date
        </label>
        <input
          type="date"
          id="dueDate"
          value={dueDate}
          onChange={(e) => setDueDate(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="document" className="block text-gray-700 text-sm font-bold mb-2">
          Attach Document
        </label>
        <div className="flex items-center">
          <input
            type="file"
            id="document"
            onChange={handleFileChange}
            className="hidden"
          />
          <label
            htmlFor="document"
            className="cursor-pointer bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
          >
            <Upload className="mr-2" size={16} />
            {file ? 'Change File' : 'Upload File'}
          </label>
          {file && <span className="ml-2 text-sm text-gray-600">{file.name}</span>}
        </div>
      </div>
      <div className="flex items-center justify-between">
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          {task ? 'Update Task' : 'Create Task'}
        </button>
        <button
          type="button"
          onClick={onCancel}
          className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <XIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
          Cancel
        </button>
      </div>
    </form>
  );
};

export default TaskForm;