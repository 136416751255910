import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createDocument } from '../services/document.service';
import { addDocument } from '../store/slices/documentSlice';

interface Document {
  id: string;
  name: string;
  content: string; // Added this line
  organizationId: string;
  createdBy: string;
  createdAt: Date;
  updatedAt: Date;
  projectId?: string;
}

interface DocumentUploadProps {
  organizationId: string;
  projectId?: string;
  userId: string;
}

const DocumentUpload: React.FC<DocumentUploadProps> = ({ organizationId, projectId, userId }) => {
  const [file, setFile] = useState<File | null>(null);
  const dispatch = useDispatch();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (file) {
      try {
        const content = await file.text();
        const documentData: Omit<Document, 'id' | 'createdAt' | 'updatedAt'> & { name: string } = {
          name: file.name,
          content,
          organizationId,
          createdBy: userId,
        };

        // Only add projectId if it's provided and not undefined
        if (projectId) {
          documentData.projectId = projectId;
        }

        const newDoc = await createDocument(documentData, userId);
        dispatch(addDocument(newDoc));
        setFile(null); // Reset file input after successful upload
      } catch (error) {
        console.error('Error uploading document:', error);
        // Handle error (e.g., show error message to user)
      }
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload} disabled={!file}>Upload</button>
    </div>
  );
};

export default DocumentUpload;