import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, UpdateUserDTO } from '../../types/user';
import { AppThunk } from '../index';
import { updateUser as updateUserService, fetchUserFromFirestore } from '../../services/user.service';

interface UserState {
  currentUser: User | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: UserState = {
  currentUser: null,
  isLoading: false,
  error: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.currentUser = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    clearUser: (state) => {
      state.currentUser = null;
      state.isLoading = false;
      state.error = null;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    updateUserSuccess: (state, action: PayloadAction<User>) => {
      state.currentUser = action.payload;
      state.isLoading = false;
      state.error = null;
    },
  },
});

export const { setUser, clearUser, setLoading, setError, updateUserSuccess } = userSlice.actions;

export const signInUser = (userId: string): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const fetchedUser = await fetchUserFromFirestore(userId);
    dispatch(setUser(fetchedUser));
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
    dispatch(setError(`Failed to sign in: ${errorMessage}`));
  } finally {
    dispatch(setLoading(false));
  }
};
export const updateUser = (userData: UpdateUserDTO): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const updatedUser = await updateUserService(userData);
    dispatch(updateUserSuccess(updatedUser));
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
    dispatch(setError(`Failed to update user profile: ${errorMessage}`));
  } finally {
    dispatch(setLoading(false));
  }
};

export default userSlice.reducer;
