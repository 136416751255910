import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createOrganization } from '../services/organization.service';
import { addOrganization } from '../store/slices/organizationSlice';
import { CreateOrganizationDTO } from '../types/organization';
import { RootState } from '../store';

const OrganizationForm: React.FC<{ onOrganizationCreated?: (orgId: string) => void }> = ({ onOrganizationCreated }) => {
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.user.currentUser);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUser) {
      console.error('No logged-in user found');
      return;
    }
    setIsLoading(true); // Start loading
    const newOrg: CreateOrganizationDTO = { 
      name, 
      ownerId: currentUser.id,
      ownerUsername: currentUser.username,
    };
    try {
      const createdOrg = await createOrganization(newOrg);
      dispatch(addOrganization(createdOrg));
      if (onOrganizationCreated) {
        onOrganizationCreated(createdOrg.id);
      }
      setName(''); // Clear input on success
    } catch (error) {
      console.error('Error creating organization:', error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label htmlFor="orgName" className="block text-sm font-medium text-gray-700">
          Organization Name
        </label>
        <input
          type="text"
          id="orgName"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter organization name"
          required
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <button
        type="submit"
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        disabled={isLoading}
      >
        {isLoading ? 'Creating...' : 'Create Organization'}
      </button>
    </form>
  );
};

export default OrganizationForm;
