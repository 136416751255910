import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInvitations, acceptOrganizationInvitation } from '../services/organization.service';
import { setInvitations, updateInvitationStatus } from '../store/slices/organizationSlice';
import { RootState } from '../store';

const InvitationsList: React.FC = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: RootState) => state.user);
  const { invitations } = useSelector((state: RootState) => state.organization);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchInvitations = async () => {
      if (currentUser) {
        try {
          const fetchedInvitations = await getInvitations(currentUser.id);
          console.log('Dispatching Fetched Invitations:', fetchedInvitations); // Debug output
          dispatch(setInvitations(fetchedInvitations));
        } catch (error) {
          console.error('Error fetching invitations:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };
  
    fetchInvitations();
  }, [currentUser, dispatch]);
  
  useEffect(() => {
    console.log('Invitations from Redux state:', invitations); // Debug output
  }, [invitations]);
  

  const handleAccept = async (invitationId: string) => {
    try {
      // Accept the invitation using the updated function
      await acceptOrganizationInvitation(invitationId);
      // Find and update the invitation status in the Redux store
      const updatedInvitation = invitations.find(inv => inv.id === invitationId);
      if (updatedInvitation) {
        dispatch(updateInvitationStatus({ id: invitationId, status: 'accepted' }));
      }
    } catch (error) {
      console.error('Error accepting invitation:', error);
    }
  };

  if (isLoading) {
    return <div>Loading invitations...</div>;
  }

  if (invitations.length === 0) {
    return <div>You have no pending invitations.</div>;
  }

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-bold">Pending Invitations</h2>
      {invitations.map((invitation) => (
        <div key={invitation.id} className="border p-4 rounded-md">
          <p>Organization: {invitation.organizationId}</p>
          <button
            onClick={() => handleAccept(invitation.id)}
            className="mt-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Accept Invitation
          </button>
        </div>
      ))}
    </div>
  );
};

export default InvitationsList;
