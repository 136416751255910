import axios from 'axios';
import { Project, Task } from '../types';

const API_URL = process.env.REACT_APP_AI_API_URL;

export interface AIResponse {
  text: string;
  suggestedActions: string[];
}

export interface AIContext {
  projectId?: string;
  userId: string;
  context: string;
}

export const getProjectContext = async (project: Project, tasks: Task[]): Promise<string> => {
  try {
    // Format project details and tasks into a concise context string
    const contextString = `
      Project: ${project.name}
      Description: ${project.description || 'No description provided'}
      Tasks: ${tasks.map(task => `${task.title} (${task.status})`).join(', ')}
    `;

    return contextString.trim();
  } catch (error) {
    console.error('Error creating project context:', error);
    throw new Error('Failed to create project context');
  }
};

export const askAI = async (question: string, aiContext: AIContext): Promise<AIResponse> => {
  console.log('Asking AI:', { question, context: aiContext.context, projectId: aiContext.projectId || 'No project selected' });
  console.log('API_URL:', API_URL);
  
  try {
    const response = await axios.post(`/api/ai/ask`, { question, ...aiContext });
    console.log('AI Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error communicating with AI:', error);
    if (axios.isAxiosError(error)) {
      if (error.response) {
        console.error('Server response:', error.response.data);
        throw new Error(`Failed to get AI response: ${error.response.status} - ${JSON.stringify(error.response.data)}`);
      } else if (error.request) {
        console.error('No response received:', error.request);
        throw new Error('Failed to get AI response: No response received from server');
      } else {
        console.error('Error setting up request:', error.message);
        throw new Error(`Failed to get AI response: ${error.message}`);
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error('Failed to get AI response: Unexpected error occurred');
    }
  }
};

export const executeSuggestedAction = async (action: string, aiContext: AIContext): Promise<any> => {
  console.log('Executing action:', { action, context: aiContext.context });
  
  try {
    const response = await axios.post(`${API_URL}/execute`, { action, ...aiContext });
    console.log('Action execution response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error executing suggested action:', error);
    if (axios.isAxiosError(error) && error.response) {
      console.error('Server response:', error.response.data);
    }
    throw new Error('Failed to execute suggested action: ' + (error as Error).message);
  }
};

export const saveInteraction = async (userId: string, query: string, response: string): Promise<void> => {
  try {
    await axios.post(`${API_URL}/interactions`, { userId, query, response });
    console.log('Interaction saved successfully');
  } catch (error) {
    console.error('Error saving interaction:', error);
    // We don't throw an error here as this is not critical for the user experience
  }
};